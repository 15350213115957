import * as React from 'react';
const INDETERMINATE_STATE = 'indeterminate';
const dispatch = (value) => (state) => {
    if (Array.isArray(state)) {
        const index = state.indexOf(value);
        if (index === -1) {
            state.push(value);
        }
        else {
            state.splice(index, 1);
        }
        return [...state];
    }
    else if (value !== '') {
        return [value];
    }
    return !state;
};
export const useCheckboxState = ({ state: initialState = false, onChange, } = {}) => {
    const [state, setState] = React.useState(() => {
        if (typeof initialState === 'string' && initialState !== INDETERMINATE_STATE) {
            return [initialState];
        }
        return initialState;
    });
    return {
        state,
        setState,
        onChange: React.useCallback((e) => {
            const { value } = e.currentTarget;
            setState(dispatch(value));
            if (typeof onChange === 'function') {
                onChange(e);
            }
        }, [onChange]),
    };
};
